import React, { Component } from "react"
import PropTypes from "prop-types"
import { matchPath } from "react-router"
import { NavLink } from "react-router-dom"
import { connect } from "react-redux"
import classnames from "classnames"
import { memoize } from "lodash"

import Menu from "containers/Menu"
import MenuItem from "components/MenuItem"
import LazyMenuItem from "containers/LazyMenuItem"
import Icon, { IconTypes } from "components/Icon"
import mainMenuConfig from "containers/PageSEOHome/texts"

import { hideSEONav } from "actions/ui"

import formatterHelper from "helpers/formatter"
import pictureHelper from "helpers/picture"

import { selectReferencesModel } from "selectors/references"
import { selectSeoMenuModel } from "selectors/seoMenu"

import styles from "./index.css"
import consts from "consts"
import I18n from "../../consts/i18n"

@connect((state, ownProps) => ({
  locale: state.locale.locale,
  pathname: ownProps.location.pathname,
  references: selectReferencesModel(state),
  menuItems: selectSeoMenuModel(state),
  windowWidth: state.ui.windowWidth,
}))
export default class SEOMenu extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    pathname: PropTypes.string,
    references: PropTypes.object.isRequired,
    rootStyle: PropTypes.string.isRequired,
    menuItems: PropTypes.object.isRequired,
    windowWidth: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
  }

  static defaultProps = {
    rootStyle: "",
    windowWidth: null,
  }

  closeMobileMenu = () => {
    this.props.dispatch(hideSEONav())
  }

  renderAllLinks = () => {
    return matchPath(this.props.pathname, consts.routes.seoHome) || matchPath(this.props.pathname, consts.routes.category)
  }

  getCurrentBundle = memoize((channelId, tvBundles) => {
    return tvBundles.find(b => b.channel_ids.indexOf(channelId) !== -1)
  })

  isBundleLabelActive = (match, tvBundles, tvBundle) => {
    let isActive = false

    if (match && match.params && match.params.channelId) {
      const currentBundle = this.getCurrentBundle(match.params.channelId, tvBundles)

      if (currentBundle && (currentBundle.id === tvBundle.id || this.isTNTChannel(currentBundle, tvBundle))) {
        isActive = true
      }
    }

    return isActive
  }

  isMovieLabelActive = match => {
    if (match && match.params && match.params.categoryId) {
      const categoryMovieId = "1"
      if (match.params.categoryId === categoryMovieId) {
        return true
      }
    }
    return false
  }

  isTNTChannel = (current, bundle) => {
    const TFI_GROUP_ID = "146"
    const M6_GROUP_ID = "142"
    const FRANCETV_GROUP_ID = "153"
    const TF1_4K_GROUP_ID = "149"

    const TNT_GROUP_ID = "25"

    return (
      bundle.id === TNT_GROUP_ID &&
      (current.id === TFI_GROUP_ID || current.id === M6_GROUP_ID || current.id === FRANCETV_GROUP_ID || current.id === TF1_4K_GROUP_ID)
    )
  }

  renderMainMenuLabel = match => (
    <span>
      Programme TV{" "}
      <Icon type={IconTypes.arrowLeft} rootStyle={styles.menuArrow} iconStyle={classnames(styles.arrowIcon, { [styles.arrowIconActive]: match })} />
    </span>
  )

  renderChannelDirectMenuLabel = match => (
    <span>
      Chaînes{" "}
      <Icon type={IconTypes.arrowLeft} rootStyle={styles.menuArrow} iconStyle={classnames(styles.arrowIcon, { [styles.arrowIconActive]: match })} />
    </span>
  )

  renderChannelReplayMenuLabel = match => (
    <span>
      En Replay{" "}
      <Icon type={IconTypes.arrowLeft} rootStyle={styles.menuArrow} iconStyle={classnames(styles.arrowIcon, { [styles.arrowIconActive]: match })} />
    </span>
  )

  renderCategoriesMenuLabel = match => (
    <span>
      Catégories{" "}
      <Icon type={IconTypes.arrowLeft} rootStyle={styles.menuArrow} iconStyle={classnames(styles.arrowIcon, { [styles.arrowIconActive]: match })} />
    </span>
  )

  renderMainMenu = open => {
    const { locale } = this.props

    return this.renderAllLinks() || open ? (
      <>
        <MenuItem>
          <NavLink to={formatterHelper.basic(consts.routes.seoHome, { locale })} activeClassName={styles.active} exact>
            Programme TV
          </NavLink>
        </MenuItem>
        {Object.keys(mainMenuConfig).map(slug => {
          const entry = mainMenuConfig[slug]

          if (!entry.menuLabel) {
            return null
          }

          return (
            <MenuItem key={slug}>
              <NavLink
                to={formatterHelper.basic(consts.routes.seoHomeSection, { locale, section: consts.slugs[slug] })}
                activeClassName={styles.active}
              >
                {entry.menuLabel}
              </NavLink>
            </MenuItem>
          )
        })}
      </>
    ) : null
  }

  renderChannelMenu = () => {
    const { locale, pathname, references } = this.props
    const tvBundles = references.getTvBundles()

    const TFI_GROUP_ID = "146"
    const M6_GROUP_ID = "142"
    const FRANCETV_GROUP_ID = "153"
    const TF1_4K_GROUP_ID = "149"

    const TNT_GROUP_ID = "25"

    const bundleTF1 = references.getTvBundle(TFI_GROUP_ID)
    const channelsTF1 = bundleTF1 ? bundleTF1.channels : []

    const bundleTF14K = references.getTvBundle(TF1_4K_GROUP_ID)
    const channelsTF14K = bundleTF14K ? bundleTF14K.channels : []

    const bundleM6 = references.getTvBundle(M6_GROUP_ID)
    const channelsM6 = bundleM6 ? bundleM6.channels : []

    const bundleFrance = references.getTvBundle(FRANCETV_GROUP_ID)
    const channelsFrance = bundleFrance ? bundleFrance.channels : []

    const displayFirstLinks = matchPath(pathname, consts.routes.channel) || matchPath(pathname, consts.routes.program)
    const renderAllLinks = this.renderAllLinks()

    return (
      <>
        {tvBundles.map((tvbundle, index) => {
          const channels =
            tvbundle.id === TNT_GROUP_ID ? channelsTF1.concat(channelsTF14K, channelsFrance, channelsM6, tvbundle.channels) : tvbundle.channels
          const mainBundle = index === 0

          return channels.length &&
            tvbundle.id !== M6_GROUP_ID &&
            tvbundle.id !== TFI_GROUP_ID &&
            tvbundle.id !== FRANCETV_GROUP_ID &&
            tvbundle.id !== TF1_4K_GROUP_ID ? (
            <MenuItem key={`menu-tvbundle-${tvbundle.id}`}>
              <Menu
                id={`sub-tvbundle-${tvbundle.id}`}
                subMenu={true}
                label={<span>{tvbundle.title}</span>}
                isLabelActive={match => this.isBundleLabelActive(match, tvBundles, tvbundle)}
                children={open => (
                  <>
                    {channels.slice(0, open || renderAllLinks ? channels.length : displayFirstLinks && mainBundle ? 8 : 0).map(
                      channel =>
                        !channel.isVirtual() && (
                          <MenuItem key={`channel-${channel.getId()}`} rootStyle={styles.channelMenuItem}>
                            <NavLink
                              className={styles.channelLink}
                              to={channel.getUrl(locale)}
                              onClick={this.closeMobileMenu}
                              activeClassName={styles.active}
                            >
                              {channel.data.title} direct
                              <LazyMenuItem menuId={`sub-tvbundle-${tvbundle.id}`}>
                                <img
                                  className={classnames(styles.channelLogo, styles.channelLogoDesktop)}
                                  src={pictureHelper.replaceProtocol(channel.getImageUrl("small", "dark"))}
                                  alt={`Regarder ${channel.getTitle()} en direct`}
                                />
                                <img
                                  className={classnames(styles.channelLogo, styles.channelLogoMobile)}
                                  src={pictureHelper.replaceProtocol(channel.getImageUrl("small", "light"))}
                                  alt={`Regarder ${channel.getTitle()} en direct`}
                                />
                              </LazyMenuItem>
                            </NavLink>
                          </MenuItem>
                        )
                    )}
                  </>
                )}
              />
            </MenuItem>
          ) : null
        })}
      </>
    )
  }

  renderReplayChannelMenu = () => {
    const { locale, pathname, references } = this.props
    const tvBundles = references.getTvBundles()

    const TFI_GROUP_ID = "146"
    const M6_GROUP_ID = "142"
    const FRANCETV_GROUP_ID = "153"

    const TNT_GROUP_ID = "25"

    const bundleTF1 = references.getTvBundle(TFI_GROUP_ID)
    const channelsTF1 = bundleTF1 ? bundleTF1.channels : []

    const bundleM6 = references.getTvBundle(M6_GROUP_ID)
    const channelsM6 = bundleM6 ? bundleM6.channels : []

    const bundleFrance = references.getTvBundle(FRANCETV_GROUP_ID)
    const channelsFrance = bundleFrance ? bundleFrance.channels : []

    const isChannelReplayPage = matchPath(pathname, consts.routes.channelReplay)
    const renderAllLinks = this.renderAllLinks()

    return (
      <>
        {tvBundles.map((tvbundle, index) => {
          const replayChannels =
            tvbundle.id === TNT_GROUP_ID
              ? channelsTF1
                  .concat(channelsFrance, channelsM6, tvbundle.channels)
                  .filter(c => !c.isVirtual() || c.getTitle() === "Culturebox")
                  .filter(c => c.hasReplay())
              : tvbundle.channels.filter(c => c.hasReplay())
          const mainBundle = index === 0
          return replayChannels.length && tvbundle.id !== M6_GROUP_ID && tvbundle.id !== TFI_GROUP_ID && tvbundle.id !== FRANCETV_GROUP_ID ? (
            <MenuItem key={`menu-tvbundle-replay-${tvbundle.id}`}>
              <Menu
                id={`sub-tvbundle-replay-${tvbundle.id}`}
                subMenu={true}
                label={<span>{tvbundle.title}</span>}
                isLabelActive={match => this.isBundleLabelActive(match, tvBundles, tvbundle)}
                children={open => (
                  <>
                    {replayChannels
                      .slice(0, open || renderAllLinks ? replayChannels.length : isChannelReplayPage && mainBundle ? 8 : 0)
                      .map(channel => (
                        <MenuItem key={`channel-${channel.getId()}`} rootStyle={styles.channelMenuItem}>
                          <NavLink
                            className={styles.channelLink}
                            to={channel.getReplayUrl(locale)}
                            onClick={this.closeMobileMenu}
                            activeClassName={styles.active}
                          >
                            {channel.data.title} replay
                            <LazyMenuItem menuId={`sub-tvbundle-replay-${tvbundle.id}`}>
                              <img
                                className={classnames(styles.channelLogo, styles.channelLogoDesktop)}
                                src={pictureHelper.replaceProtocol(channel.getImageUrl("small", "dark"))}
                                alt={`Regarder ${channel.getTitle()} en replay`}
                              />
                              <img
                                className={classnames(styles.channelLogo, styles.channelLogoMobile)}
                                src={pictureHelper.replaceProtocol(channel.getImageUrl("small", "light"))}
                                alt={`Regarder ${channel.getTitle()} en replay`}
                              />
                            </LazyMenuItem>
                          </NavLink>
                        </MenuItem>
                      ))}
                  </>
                )}
              />
            </MenuItem>
          ) : null
        })}
      </>
    )
  }

  renderCategoriesMenu = () => {
    const { locale, references, menuItems } = this.props

    return (
      <>
        {references?.categories?.map(category =>
          category.label === "Films" ? (
            <MenuItem key={`category-${category.id}`}>
              <Menu
                id={`movie-genre-${category.id}`}
                subMenu
                label={
                  <a
                    href={formatterHelper.basic(consts.routes.category, {
                      locale,
                      categoryId: 1,
                      categorySlug: "movies",
                    })}
                    onClick={e => e.preventDefault()}
                  >
                    Films
                  </a>
                }
                isLabelActive={match => this.isMovieLabelActive(match)}
                children={() => (
                  <>
                    {menuItems?.getMovies()?.map(genre => {
                      const urlSplitResult = formatterHelper.urlSplit(genre.path)
                      return (
                        <MenuItem key={`genre-${genre.ID}`} rootStyle={styles.channelMenuItem}>
                          <NavLink
                            className={styles.channelLink}
                            to={
                              genre.ID
                                ? formatterHelper.basic(consts.routes.genre, {
                                    locale,
                                    ...urlSplitResult,
                                  })
                                : formatterHelper.basic(consts.routes.category, {
                                    locale,
                                    categoryId: category.id,
                                    categorySlug: category.slug_seo,
                                  })
                            }
                            activeClassName={styles.active}
                            exact={true}
                          >
                            {genre.title}
                          </NavLink>
                        </MenuItem>
                      )
                    })}
                  </>
                )}
              />
            </MenuItem>
          ) : (
            <MenuItem key={`category-${category.id}`}>
              <NavLink
                to={formatterHelper.basic(consts.routes.category, {
                  locale,
                  categoryId: category.id,
                  categorySlug: category.slug_seo,
                })}
                activeClassName={styles.active}
              >
                {category.label}
              </NavLink>
            </MenuItem>
          )
        )}
      </>
    )
  }

  render() {
    const {
      location: { pathname },
      references,
      rootStyle,
      windowWidth,
    } = this.props

    /**
            Explanation of what should be rendered depending on the page we're on:
            - on the seo homepage / category page: we render ALL links in menus
            - on channels / program pages: we only render the first 8 links of the "channels" menu
            - on replay channels: we only render the first 8 links of the "en replay" menu

            This applies to when the menus are closed, when they are open we render everything for the
            opened menu
        **/

    const isMobile = windowWidth < 800

    return (
      <div className={rootStyle} data-test="seo-menu">
        <Menu
          path={consts.routes.seoHome}
          id="mainMenu"
          label={this.renderMainMenuLabel}
          rootStyle={styles.menu}
          children={this.renderMainMenu}
          key={`main-menu-${pathname}`}
        />
        <Menu
          path={consts.routes.channel}
          id="channels"
          label={this.renderChannelDirectMenuLabel}
          rootStyle={styles.menu}
          children={this.renderChannelMenu}
          key={`channels-${references.getTvBundles().length}-${pathname}`}
        />
        <Menu
          path={consts.routes.channelReplay}
          id="channels-replay"
          label={this.renderChannelReplayMenuLabel}
          rootStyle={styles.menu}
          children={this.renderReplayChannelMenu}
          key={`channels-replay-${references.getTvBundles().length}-${pathname}`}
        />
        <Menu
          path={consts.routes.category}
          id="categories"
          label={this.renderCategoriesMenuLabel}
          rootStyle={styles.menu}
          children={this.renderCategoriesMenu}
          key={`categories-${references.categories.length}-${pathname}`}
        />
        {!isMobile ? (
          <a className={styles.headerLogoOlympic} href={consts.externalLink.molotovTvAwards} aria-label="molotov tv awards">
            <Icon type={IconTypes.molotovTvAwards} />
          </a>
        ) : (
          <div className={styles.mobileOlympic}>
            {" "}
            <a href={consts.externalLink.molotovTvAwards} aria-label="molotov tv awards">
              {I18n.publicMenu.molotovTvAwards}
            </a>
          </div>
        )}
      </div>
    )
  }
}
