export default {
  beta: {
    wall: "/_1571995959_beta_wall_1180.jpg",
    popcorn1: "/_1571996624_visuel_beta.png",
    popcorn2: "/_1571996624_visuel_beta_2x.png",
    popcorn3: "/_1571996624_visuel_beta_3x.png",
  },

  video: {
    home: {
      mp4: "/recherche-81077d8f369bb81a9e0db3fd4eff9888.mp4",
      ogg: "/recherche-a720edd4bca7529a3e93fa003c50e937.ogg",
      webm: "/recherche-4cf813bcd86db7c23ae2236e33cba5a6.webm",
    },
    travel: "/header-0869f9fc3193e488a69029b45cf57b22.mp4",
    theMatch: "/the-match-video-11-14-2018.mp4",
    westworld: "https://player.vimeo.com/video/395232414",
  },

  image: {
    deviceLinks: {
      tvImage: "/_1536328906_DeviceLinks-tv.svg",
      desktopImage: "/_1536328906_DeviceLinks-desktop.svg",
      tabletImage: "/_1536328906_DeviceLinks-tablet.svg",
      phoneImage: "/_1536328906_DeviceLinks-phone.svg",
    },

    googleAssistant: {
      headerImage: "/_1592486866_illus_Nest_Hub.png",
      headerImage2x: "/_1592486914_illus_Nest_Hub2x.png",
      nesthubImage: "/_1593545986_illus_Nest_Hub_2.png",
      nesthubImage2x: "/_1593545979_illus_Nest_Hub_2x2.png",
      startOverImage: "/_1593546041_illus_Nest_Hub_start_over.png",
      startOverImage2x: "/_1593546007_illus_Nest_Hub_start_overx2.png",
    },

    liveLabel: {
      liveLabelBigImage: "/_1536329150_LiveLabel-big.gif",
      liveLabelSmallImage: "/_1536329150_LiveLabel-small.gif",
    },

    pageAmazonAlexa: {
      headerImage: "/_1593551067_illus_Echo_Show_3.png",
      headerImage2x: "/_1593551071_illus_Echo_Show_3x2.png",
      echoShowImage: "/_1592485365_illus_Echo_Show.png",
      echoShowImage2x: "/_1592485476_illus_Echo_Show2x.png",
      startOverImage: "/_1593551056_illus_Echo_Show_Start_Over.png",
      startOverImage2x: "/_1593551064_illus_Echo_Show_Start_Overx2.png",
    },

    pageErrors: {
      notFound: "/_1536235401_PageError-not-found-tv.png",
    },

    pageDeeplinkGeneric: {
      molotovLogo: "/_1536236728_PageDeeplinkGeneric-app-icon-molotov.svg",
    },

    pageAbout: {
      jeanDavidBlancImage: "/_1536240297_PageAbout-jeandavid_blanc.png",
      pierreLescureImage: "/_1536240300_PageAbout-pierre_lescure.png",
      jeanMarcDenoualImage: "/_1536240295_PageAbout-jean-marc_denoual.png",
    },

    pageAfrican: {
      africanBasicLogo: "/_1599465935_africain_basic_logo_2x.png",
      africanLogo: "/_1599161026_africain_logo_2x.png",
      africanPreniumLogo: "/_1599422207_africain_prenium_logo_2x.png",
      aPlusLogo: "/_1599164122_africain_aplus_logo_2x.png",
      aPlusLogoTV: "/_1599164188_africain_aplus_logo_tv_2x.png",
      cacaoLogo: "/_1598256596_logo-cacao@2x.png",
      cacaoLogoTV: "/_1599163019_africain_cacao_logo_tv_2x.png",
      canalLogo: "/_1599464547_africain_canal_logo_2x.png",
      jenifaLogo: "/_1599208600_africain_jenifa_logo_2x.png",
      meryemLogo: "/_1599161181_africain_meryem_logo_2x.png",
      molotovLogo: "/_1599221023_africain_molotov_logo_2x.png",
      nollywoodLogo: "/_1611755380_Nollywood_TV_Logo_Colour.png",
      novelasLogo: "/_1599161216_africain_novelas_logo_2x.png",
    },

    pageAndroid: {
      headerImage: "/_1620740854_page_android_header.webp",
      headerImage2x: "/_1620740852_page_android_header_x2.webp",
      chromecastImage: "/_1620740849_page_android_chromecast.webp",
      chromecastImage2x: "/_1620740846_page_android_chromecast_x2.webp",
      loginImage: "/_1620740859_page_android_Play_store.webp",
      loginImage2x: "/_1620740857_page_android_Play_store_x2.webp",
      tabletImage: "/_1620740865_page_android_tablet.webp",
      tabletImage2x: "/_1620740862_page_android_tablet_x2.webp",
    },

    pageHuawei: {
      headerImage: "/_1607954877_494x294-Huawei.png",
      headerImage2x: "/_1607696664_494x294-Huawei@x2.png",
      huaweiLive: "/_1594755026_illus_Huawei_live.png",
      huaweiLive2x: "/_1594755026_illus_Huawei_live.png",
      huaweiImage: "/_1594755032_illus_Huawei_image.png",
      huaweiImage2x: "/_1594755032_illus_Huawei_image.png",
      huaweiStoreImage: "/_1594755037_illus_Huawei_store.png",
      huaweiStoreImage2x: "/_1594755037_illus_Huawei_store.png",
    },

    pageAppleTv: {
      headerImage: "/_1620741044_page_Apple_TV_header.webp",
      headerImage2x: "/_1620741042_page_Apple_TV_header_x2.webp",
      programsImage: "/_1620741039_page_Apple_TV_enregistrement.webp",
      programsImage2x: "/_1620741037_page_Apple_TV_enregistrement_x2.webp",
      installImage: "/_1620741049_page_Apple_TV_home.webp",
      installImage2x: "/_1620741046_page_Apple_TV_home_x2.webp",
    },

    pageCampaign: {
      cdiscountImage: "/_1536242469_PageCompaign-cdiscount1x.png",
      cdiscountImage2x: "/_1536242469_PageCompaign-cdiscount2x.png",
    },

    pageChromecast: {
      headerImage: "/_1620741215_page_Chromecast_header.webp",
      headerImage2x: "/_1620741213_page_Chromecast_header_x2.webp",
      channelsImage: "/_1620741224_page_Chromecast_remote.webp",
      channelImage2x: "/_1620741222_page_Chromecast_remote_x2.webp",
      chromecastImage: "/_1620741202_page_Chromecast_appareil.webp",
      chromecastImage2x: "/_1620741200_page_Chromecast_appareil_x2.webp",
      illusChromecastImage: "/_1620741219_page_Chromecast_icone.webp",
      illusChromecastImage2x: "/_1620741217_page_Chromecast_icone_x2.webp",
      chromecastAppImage: "/_1620741206_page_Chromecast_cast.webp",
      chromecastAppImage2x: "/_1620741204_page_Chromecast_cast_x2.webp",
    },

    pageDesktop: {
      headerImage: "/_1620741483_page_desktop_header.webp",
      headerImage2x: "/_1620741481_page_desktop_header_x2.webp",
      programsImage: "/_1620741478_page_desktop_enregistrement.webp",
      programsImage2x: "/_1620741476_page_desktop_enregistrement_x2.webp",
      channelsImage: "/_1620741474_page_desktop_chaines.webp",
      channelsImage2x: "/_1620741471_page_desktop_chaines_x2.webp",
      desktopImage: "/_1620741496_page_desktop_start_over.webp",
      desktopImage2x: "/_1620741485_page_desktop_start_over_x2.webp",
    },

    pageDirect: {
      headerImage: "/_1536245491_PageDirect-header.png",
      directImage: "/_1556287527_PageDirect-direct.png",
      directImage2x: "/_1556287524_PageDirect-direct2x.png",
    },

    pageDownloading: {
      windowsDownloadImage: "/_1536245990_PageDownloading-windows-download.png",
      windowsInstallImage: "/_1536245990_PageDownloading-windows-install.png",
      macInstallImage: "/_1536246080_PageDownloading-mac-install.png",
      macChromeDownloadImage: "/_1536246080_PageDownloading-mac-chrome.png",
      macSafariDownloadImage: "/_1536246080_PageDownloading-mac-safari.png",
      connectImage: "/_1536246195_PageDownloading-connect.png",
      watchImage: "/_1707226899_PgaDowloading-watch (2).png",
    },

    pageFreeChannels: {
      headerImage: "/_1556287884_PageFreeChannels-header.png",
      headerImage2x: "/_1556287889_PageFreeChannels-header2x.png",
      devicesImage: "/_1556287672_PageFreeChannels-devices.png",
      devicesImage2x: "/_1556287672_PageFreeChannels-devices2x.png",
    },

    pageHardware: {
      appStoreImage: "/_1536247531_PageHardware-link_appstore.png",
      huaweiStoreImage: "/_1595497356_huawei_store_image.png",
      illusAndroidImage: "/_1707226664_Tablet mobile Android 494x294 @x4 (1).png",
      illusAndroidImage2x: "/_1707227439_Tablet mobile Android 494x294 @x4 (4).png",
      illusAppleTVImage: "/_1556033581_PageHardware-illus_appletv.png",
      illusAppleTVImage2x: "/_1556029746_PageHardware-illus_appletv2x.png",
      illusBrowserImage: "/_1628174545_PageHardware-illus_browser_2x.webp",
      illusBrowserImage2x: "/_1628174545_PageHardware-illus_browser_2x.webp",
      illusChromeCastImage: "/_1607356361_hardwareChromecast.png",
      illusChromeCastImage2x: "/_1607356361_hardwareChromecast@2.png",
      illusDesktopImage: "/_1556184502_PageHardware-illus_desktop.png",
      illusDesktopImage2x: "/_1556029663_PageHardware-illus_desktop2x.png",
      illusEchoShow: "/_1592485365_illus_Echo_Show.png",
      illusEchoShow2x: "/_1592485476_illus_Echo_Show2x.png",
      illusFireStick: "/_1620741779_page_device_fire_stick.webp",
      illusFireStick2x: "/_1620741776_page_device_fire_stick_x2.webp",
      illusGoogleChromecast: "/_1550850191_PageHardware-google_chromecast_molotov.jpg",
      illusHisense: "/_1620741877_page_device_Hisense.webp",
      illusHisense2x: "/_1620741875_page_device_Hisense_x2.webp",
      illusHuawei: "/_1607954877_494x294-Huawei.png",
      illusHuawei2x: "/_1607696664_494x294-Huawei@x2.png",
      illusNestHub: "/_1592486866_illus_Nest_Hub.png",
      illusNestHub2x: "/_1592486914_illus_Nest_Hub2x.png",
      illusIphoneImage: "/_1556028610_PageHardware-illus_ios.png",
      illusIphoneImage2x: "/_1556636841_PageHardware-illus_ios2x.png",
      illusLGImage: "/_1556640784_PageHardware-illus_lg.png",
      illusLGImage2x: "/_1556640794_PageHardware-illus_lg2x.png",
      illusNvidiaShield: "/_1620741953_page_device_NVIDIA_Shield.webp",
      illusNvidiaShield2x: "/_1620741950_page_device_NVIDIA_Shield_x2.webp",
      illusOculus: "/_1550851491_PageHardware-oculus.png",
      illusPanasonicImage: "/_1556636649_PanasonicTV.png",
      illusPanasonicImage2x: "/_1556636649_PanasonicTV@2x.png",
      illusSamsungImage: "/_1556640375_PageHardware-illus_samsung.png",
      illusSamsungImage2x: "/_1556640398_PageHardware-illus_samsung2x.png",
      illusSonyImage: "/_1620742010_page_device_sony.webp",
      illusSonyImage2x: "/_1620742007_page_device_sony_x2.webp",
      illusSourcePanasonicImage: "/_1602443145_illus_source_Panasonic.png",
      illusSourcePanasonicImage2x: "/_1602443149_illus_source_Panasonic_x2.png",
      illusTCLImage: "/_1620742061_page_device_THOMSON.webp",
      illusTCLImage2x: "/_1620742059_page_device_THOMSON_x2.webp",
      illusXiaomi: "/_1598211219_illus_Xiaomi_header.png",
      illusXiaomi2x: "/_1598211223_illus_Xiaomi_header2x.png",
      logoNestHub: "/_1592486827_logo_Nest_Hub.png",
      logoAndroid: "/_1536247532_PageHardware-logo_android.svg",
      logoAmazonFireStick: "/_1536247532_PageHardware-logo_amazon_firestick.png",
      logoApple: "/_1536247532_PageHardware-logo_apple.svg",
      logoAppleTv: "/_1536247533_PageHardware-logo_appletv.svg",
      logoChromeCast: "/_1536247533_PageHardware-logo_chromecast.png",
      logoHuawei: "/_1594753744_logo_Huawei.png",
      logoLG: "/_1536247533_PageHardware-logo_lg.png",
      logoLinux: "/_1536247533_PageHardware-logo_linux.png",
      logoEchoShow: "/_1592485137_logo_Echo_Show.png",
      logoNvidiaShield: "/_1536247533_PageHardware-logo_nvidiashield.png",
      logoOculus: "/_1550851432_PageHardware-oculus-logo.png",
      logoPanasonic: "/_1541153374_PanasonicLogoGrey.png",
      logoSamsung: "/_1536247534_PageHardware-logo_samsung.png",
      logoSony: "/_1536247534_PageHardware-logo_sony.png",
      logoThomson: "/_1536247534_PageHardware-logo_thomson.png",
      logoWindows: "/_1536247534_PageHardware-logo_windows.svg",
      logoXiaomi: "/_1598209121_logo_XIAOMi_Stick_TV.png",
      illusXbox: "/_1592474239_illus_Xbox_one.png",
      illusXbox2x: "/_1592487358_illus_Xbox_one2x.png",
      logoXbox: "/_1592473356_logo_Xbox.png",
      logoHisense: "/_1604674503_Hisense-Logo.png",
      playStoreImage: "/_1536247532_PageHardware-link_playstore.png",
    },

    pageHome: {
      testiTCImage: "/_1536249122_PageHome-testi_TC.png",
      testiForbesImage: "/_1536249161_PageHome-testi_forbes.png",
      testiFTImage: "/_1536249161_PageHome-testi_FT.png",
      logoTeleramaImage: "/_1536249159_PageHome-logo_telerama.png",
      logoFigaroImage: "/_1536249158_PageHome-logo_figaro.png",
      logoAppleImage: "/_1536249157_PageHome-logo_apple.svg",
      logoInrocksImage: "/_1536249158_PageHome-logo_inrocks.svg",
      logo01netImage: "/_1536249157_PageHome-logo_01net.png",
      logoCapitalImage: "/_1536249158_PageHome-logo_capital.png",
      thumbnailErrorImage: "/_1536249161_PageHome-thumbnail_error.jpg",
      logoImage: "/_1676651708_molotov_by_fubo_hardblack2.png",
      ogImage: "/_1620222252_molotov-logo.png",
      logoMolotovPlus: "/_1638195333_Topbar-Molotov_Plus.png",
      logoImageBlack: "/_1728287393_Logo_Molotov_Octobre_Rose_Black.png",
    },

    pageIphone: {
      headerImage: "/_1620742126_page_iphone_header.webp",
      headerImage2x: "/_1620742123_page_iphone_header_x2.webp",
      directImage: "/_1620742130_page_iphone_IOS_live.webp",
      directImage2x: "/_1620742128_page_iphone_IOS_live_x2.webp",
      replayImage: "/_1620742121_page_iphone_enregistrement.webp",
      replayImage2x: "/_1620742118_page_iphone_enregistrement_x2.webp",
      iphoneImage: "/_1620742116_page_iphone_Appstore.webp",
      iphoneImage2x: "/_1620742114_page_iphone_Appstore_x2.webp",
    },

    pageLG: {
      headerImage: "/_1620742306_page_LG_perso.webp",
      headerImage2x: "/_1620742304_page_LG_perso_x2.webp",
      broadcastImage: "/_1620742310_page_LG_start_over.webp",
      broadcastImage2x: "/_1620742308_page_LG_start_over_x2.webp",
      menuImage: "/_1620742297_page_LG_categories.webp",
      menuImage2x: "/_1620742295_page_LG_categories_x2.webp",
      programsImage: "/_1620742301_page_LG_enregistrement.webp",
      programsImage2x: "/_1620742299_page_LG_enregistrement_x2.webp",
      installImage: "/_1620742293_page_LG_app.webp",
      installImage2x: "/_1620742290_page_LG_app_x2.webp",
    },

    pageOAuth: {
      iconMolotovImage: "/_1536236916_PageOAuth-app-icon-molotov.svg",
    },

    pageOpApp: {
      step1: "/_1599768435_opapp_step1.jpg",
      step1_2x: "/_1599768439_opapp_step1_2x.jpg",
      step2: "/_1599768442_opapp_step2.jpg",
      step2_2x: "/_1599768444_opapp_step2_2x.jpg",
      step3: "/_1599768478_opapp_step3.jpg",
      step3_2x: "/_1599768482_opapp_step3_2x.jpg",
      step4: "/_1599768486_opapp_step4.jpg",
      step4_2x: "/_1599768498_opapp_step4_2x.jpg",
      step5: "/_1599768502_opapp_step5.jpg",
      step5_2x: "/_1599768505_opapp_step5_2x.jpg",
      step6: "/_1599768508_opapp_step6.jpg",
      step6_2x: "/_1599768511_opapp_step6_2x.jpg",
      step7: "/_1604063940_pageOpApp-step7.png",
      step8: "/_1599768520_opapp_step8.jpg",
      step8_2x: "/_1599768524_opapp_step8_2x.jpg",
      step9: "/_1599768529_opapp_step9.jpg",
      step9_2x: "/_1599768532_opapp_step9_2x.jpg",
      step10: "/_1599768535_opapp_step10.jpg",
      step10_2x: "/_1599768537_opapp_step10_2x.jpg",
      step11: "/_1599768540_opapp_step11.jpg",
      step11_2x: "/_1599768543_opapp_step11_2x.jpg",
      step12: "/_1599768545_opapp_step12.jpg",
      step12_2x: "/_1599768548_opapp_step12_2x.jpg",
      step13: "/_1599768550_opapp_step13.jpg",
      step13_2x: "/_1599768553_opapp_step13_2x.jpg",
    },

    pageOPChromecast: {
      headerBackground: "/_1550759702_PageChromecast-background.png",
      chromecastLogo: "/_1550756719_PageChromecast-headerIcon.png",
      chromecastLogo2x: "/_1550756723_PageChromecast-headerIcon2x.png",
      boulangerLogo: "/_1550765711_PageOPChromecast-boulanger.png",
      boulangerLogo2x: "/	_1550765717_PageOPChromecast-boulanger2x",
      deviceLogo: "/_1550768924_PageOPChromecast-deviceLogo.png",
      explorerLogo: "/_1550768924_PageOPChromecast-explorerLogo.png",
      recordLogo: "/_1550768924_PageOPChromecast-recordLogo.png",
      tripLogo: "/_1550768924_PageOPChromecast-tripLogo.png",
    },

    pageOnMyTv: {
      deviceImage: {
        TVHisense: "/_1604662495_OnMyTv-samsung.png",
        TVSamsung: "/_1588583857_PageOnMyTv-samsung.png",
        TVLG: "/_1588584008_PageOnMyTv-lg.png",
        TVSony: "/_1588584065_PageOnMyTv-sony.png",
        TVPhilips: "/_1588860956_PageOnMyTv-philips.png",
        TVAndroid: "/_1588584183_PageOnMyTv-androidtv.png",
        TVTCL: "/_1588584342_PageOnMyTv-tcl.png",
        TVPanasonic: "/_1588584410_PageOnMyTv-panasonictv.png",
        EchoShow: "/_1607349356_illus_echo_show_4x.png",
        GoogleNestHub: "/_1593589707_illus_Nest_Hubx4.png",
        chromecast: "/_1609767194_110x110Chromecastdevice.png",
        appletv: "/_1588584785_PageOnMyTv-appletv.png",
        amazonfirestick: "/_1588584885_PageOnMyTv-amazonfirestick.png",
        xiaomi: "/_1588585006_PageOnMyTv-xiaomi.png",
        nvidiashield: "/_1588585075_PageOnMyTv-nvidiashield.png",
        freebox4k: "/_1588585228_PageOnMyTv-freebox4k.png",
        bboxmiami: "/_1588585297_PageOnMyTv-bboxmiami.png",
        Xbox: "/_1594805492_illus_Xbox_onex4.png",
        XiaomiStick: "/_1600344141_PageOnMyTv_MiTVStick.png",
        watchTVPanasonic: "/_1606743749_PanasonicOnMyTv.png",
      },
    },

    pagePanasonic: {
      headerImage: "/_1620742723_page_Panasonic_header.webp",
      headerImage2x: "/_1620742722_page_Panasonic_header_x2.webp",
      tvImage: "/_1620742727_page_Panasonic_prog.webp",
      tvImage2x: "/_1620742726_page_Panasonic_prog_x2.webp",
      installImage: "/_1620742720_page_Panasonic_app.webp",
      installImage2x: "/_1620742718_page_Panasonic_app_x2.webp",
    },

    pageXbox: {
      headerImage: "/_1592474239_illus_Xbox_one.png",
      headerImage2x: "/_1592487358_illus_Xbox_one2x.png",
      xboxImage: "/_1593613471_illus_Xbox_one_2.png",
      xboxImage2x: "/_1593613466_illus_Xbox_one_2x2.png",
      xboxStartOverImage: "/_1593613477_illus_Xbox_one_Start_Over.png",
      xboxStartOverImage2x: "/_1593613474_illus_Xbox_one_Start_Overx2.png",
    },

    pagePayment: {
      lock: "/_1541666393_molotovpay_lock.svg",
      americanExpress: "/_1541666383_molotovpay_american_express.svg",
      visa: "/_1541666400_molotovpay_visa.svg",
      mastercard: "/_1541666397_molotovpay_mastercard.svg",
      cvv: "/_1541666389_molotovpay_cvv.svg",
      exclamationmark: "/_1542029206_exclamationmark.svg",
      chevronDown: "/_1645043610_chevron-down.png",
      chevronUp: "/_1645043610_chevron-up.png",
      check: "/_1645050361_payment_check_shape.png",
      paypal: "/_1716814562_Paypal_2014_logo.png",
    },

    pagePress: {
      m6: "/_1640254291_CPM6-ImageHeader.jpg",
      vestel: "/_1638537155_PagePress-Vestel.jpg",
      fubotv: "/_1636548226_illus_page_press_fubotv.png",
      shadowz: "/_1636548223_illus_page_press_shadowz.png",
      grandCinema: "/_1633955724_PagePress-GrandCinema.jpg",
      fiveYearsHeader: "/_1633953571_PagePress-fiveYears.jpg",
      fiveYears: "/_1633953576_PagePress-FiveYears-2.jpg",
      mtkt: "/_1628155527_MTKT_CP.png",
      mttv: "/_1628083631_MTTV_CP.png",
      mango: "/_1623249994_PagePress-CP_Mango.jpg",
      mangoFooter: "/_1623250014_PagePress-CP_Mango_Footer.jpg",
      cinema: "/_1621517278_PagePress-CP-cinema.png",
      kevAdams: "/_1617030966_PagePress-CP-Kev_Adams.jpg",
      africa: "/_1616592891_PagePress-CP_Africa.jpg",
      xbox: "/_1612964543_xboxpress.jpg",
      miss: "/_1612963195_misspress.jpg",
      scam: "/_1612962969_scam.jpg",
      warner: "/_1611566860_VISUELCPTURNERWARNERMEDIACopy3(1).jpg",
      offers: "/_1611567187_VISUELCPMOLOTOVOFFRESFINALjanv21.jpg",
      molotovLogo: "/_1610618928_molotov-logo-cp.png",
      digitalVirgoLogo: "/_1610619313_digitalvirgo-logo-cp.png",
      international: "/_1610542878_international-cp.jpg",
      internationalIlluChaines: "/_1610542953_Chaines-cp.jpg",
      kidsAndTeens: "/_1608546694_PagePress-KIDS&TEENS.jpg",
      msTraceImage: "/_1601914504_PagePresse-cp_ms_trace.jpg",
      molotovSolutionsImage: "/_1594814308_PagePress-molotov_solutions.png",
      schoolMouMolotovImage: "/_1594390411_CP-SchoolMouv.jpg",
      viacomMolotovImage: "/_1575982113_Viacom_visuel.jpg",
      themaMolotovImage: "/_1574418141_PagePress-thema.png",
      alticeMolotovImage: "/_1572004233_PagePress-altice_molotov.png",
      helfestMolotovImage: "/_1536329437_PagePress-Hellfest-Molotov_ARTE-Concert-Wide.jpg",
      logoMolotovImage: "/_1536329439_PagePress-logo.png",
      theMatchImage: "/_1543590956_PagePress-The_Match.jpg",
      panasonicHomeImage: "/_1543589051_PagePress-PANASONIC_MOLOTOV_HOME.jpg",
      arteMolotovImage: "/_1543587703_PagePress-MOLOTOV-ARTE-UHD.jpg",
      inaMolotovImage: "/_1536329438_PagePress-INA-MOLOTOV-VISUEL-WIDE.jpg",
      hybridSamsungImage: "/_1536329438_PagePress-hybrid-samsung.jpg",
      hybridSamsungTvImage: "/_1536329438_PagePress-hybrid-samsung-tv.gif",
      openEuropeImage: "/_1536329443_PagePress-openeurope.jpg",
      winamaxMolotovImage: "/_1536329446_PagePress-VISUEL-WINAMAX-MOLOTOV.jpeg",
      fourMillionsImage: "/_1536329437_PagePress-4-millions.jpg",
      tvSamsungImage: "/_1536329445_PagePress-tv-samsung.png",
      samsungImage: "/_1536329443_PagePress-samsung.png",
      tdfImage: "/_1536329444_PagePress-tdf.png",
      ocsImage: "/_1536329442_PagePress-OCS.png",
      hellfestImage: "/_1536329437_PagePress-hellfest.png",
      arteConcertImage: "/_1536329438_PagePress-logo_arte_concert.png",
      nvidiaShieldImage: "/_1536329442_PagePress-nvidia_shield.png",
      nvidiaPartenariatImage1: "/_1536329440_PagePress-nvidia_partenariat_1.jpg",
      nvidiaPartenariatImage2: "/_1536329441_PagePress-nvidia_partenariat_2.jpg",
      websiteImage: "/_1536329482_PagePress-website.jpg",
      tvMochupImage1: "/_1536329444_PagePress-tv-mockup-1.png",
      tvMochupImage2: "/_1536329445_PagePress-tv-mockup-2.png",
      sevenMillions: "/_1545149911_PagePress7Million.jpg",
      googlePlayAward: "/_1545148135_PagePressGooglePlayAward.jpg",
      rmcSportImage: "/_1550488746_PagePress-RMC_Sport.jpg",
      rmcSportChannelsImage: "/_1550485851_RMC-Sport-Chaines.jpg",
      rtImage: "/_1575370087_Visuel_CP_RT_France_trio.png",
      tenMImage: "/_1580377811_CP10M.png",
      mltvNewOffers1: "/_1602849899_PagePress_MolotovNewOffers1.jpg",
      mltvNewOffers2: "/_1606493352_illus_press_molotov_offer.jpg",
      illusPanasonic: "/_1605181085_illus_press_panasonic.jpg",
      illusPanasonicRemote: "/_1605181087_illus_press_panasonic_remote.png",
      illusMango: "/_1605181080_illus_press_mango.jpg",
      illusSonyPictures: "/_1606430457_illus_press_sony_pictures.jpg",
      illusStarz: "/_1607525342_visual_starz_cp.jpg",
      illusEchoShow: "/_1607970003_echo_show.png",
      illusEchoShow_EN: "/_1608126154_echo_show_en.jpg",
      illusMango_EN: "/_1608126150_mango_en.jpg",
      illusSonyPictures_EN: "/_1608126146_sony_en.jpg",
      filmoTV: "/_1622821598_PagePress-CP_Filmo.jpg",
      hisense: "/_1629668375_hisense_press.webp",
      mangoAVODPress: "/_1650312821_PagePress_MANGO_2022.jpg",
      browserPress: "/_1650312825_PagePress_Browser.jpg",
      FastMANGO: "/_1652969082_CP_MOLOTOV_FAST.jpg",
      FastMANGOen: "/_1652969092_CP_MOLOTOV_FAST_EN.jpg",
    },

    pageReplay: {
      headerImage: "/_1536310043_PageReplay-header.png",
      programsImage: "/_1536310043_PageReplay-programs.png",
      programsImage2x: "/_1536310044_PageReplay-programs@2x.png",
    },

    pageSamsung: {
      headerImage: "/_1620742888_page_Samsung_header.webp",
      headerImage2x: "/_1620742885_page_Samsung_header_x2.webp",
      tvImage: "/_1620742882_page_Samsung_categorie.webp",
      tvImage2x: "/_1620742880_page_Samsung_categorie_x2.webp",
      installImage: "/_1620742876_page_Samsung_app.webp",
      installImage2x: "/_1620742874_page_Samsung_app_x2.webp",
    },

    pageStreaming: {
      headerImage: "/_1536311123_PageStreaming-header.png",
      streamingImage: "/_1556288081_PageStreaming-streaming.png",
      streamingImage2x: "/_1556288078_PageStreaming-streaming2x.png",
    },

    pageSourcePanasonic: {
      headerImage: "/_1602444929_illus_source_panasonic_header.png",
      headerImage2x: "/_1602444932_illus_source_panasonic_header_x2.png",
      tvImage: "/_1602444936_illus_source_panasonic_image.jpg",
      tvImage_2x: "/_1602592173_illus_source_panasonic_image_x2.png",
      tvImage2: "/_1602444939_illus_source_panasonic_image_2.jpg",
      tvImage2_2x: "/_1602592177_illus_source_panasonic_image_2_x2.png",
      tvImage3: "/_1602444942_illus_source_panasonic_image_3.jpg",
      tvImage3_2x: "/_1602592180_illus_source_panasonic_image_3_x2.png",
      tvImage4: "/_1602444944_illus_source_panasonic_image_4.jpg",
      tvImage4_2x: "/_1602592184_illus_source_panasonic_image_4_x2.png",
      tvImage5: "/_1602444947_illus_source_panasonic_image_5.jpg",
      tvImage5_2x: "/_1602592186_illus_source_panasonic_image_5_x2.png",
    },

    pageHisense: {
      headerImage: "/_1604501898_HisenseOnMyTV.png",
      headerImage_2x: "/_1608215253_HisenseOnMyTV_2x.png",
      tvImage: "/_1604572077_Hisense-01.png",
      tvImage_2x: "/_1604572089_Hisense-01@x2.png",
      tvImage2: "/_1604572335_Hisense-02.png",
      tvImage2_2x: "/_1604572338_Hisense-02@x2.png",
      tvImage3: "/_1604572396_Hisense-03.png",
      tvImage3_2x: "/_1604572401_Hisense-03@x2.png",
      tvImage4: "/_1604572483_Hisense-04.png",
      tvImage4_2x: "/_1604572486_Hisense-04@x2.png",
      tvImage5: "/_1604579945_Hisense-05.png",
      tvImage5_2x: "/_1604579949_Hisense-05@x2.png",
    },

    pageTravel: {
      countryFlagImage: "/PageTravel-flags-",
      illuDevice: "/_1646925615_illus_travel_image.webp",
      illuDevicex2: "/_1646925615_illus_travel_image.webp",
      illuDeviceStrapi: "/_1676651537_Multidevices.png",
      illuDeviceTv: "/_1589807435_harware.png",
      illuDeviceTvx2: "/_1589807531_harware@2x.png",
      desktopPlatforms: "/_1589808052_MacWindowsLinux.png",
      appStore: "/_1589808292_APPStore.png",
      gPlay: "/_1589808277_GooglePLAY.png",
      awards: "/_1589809572_awards.png",
      awardsx2: "/_1589809574_awards@2x.png",
      stars: "/_1589809918_stars.png",
      starsx2: "/_1589809929_stars@2x.png",
      press: "/_1589812777_citations.png",
      pressx2: "/_1589812780_citations@2x.png",
      download: "/_1646925615_illus_travel_image.webp",
      molotovExtra: "/_1658387759_MolotovExtra_Black_Yellow.png",
      molotovExtra2: "/_1658836767_logo_Molotov_Extra_2.png",
      logoImage: "/_1536311700_PageTravel-logo.svg",
      scrollImage: "/_1536311701_PageTravel-scroll-icon.svg",
      checkmarkImage: "/_1536311699_PageTravel-checkmark.svg",
      molotovExtraLogo: "/_1658991922_logo-molotov-extra.svg",
    },

    tileVideo: {
      tileVideoImage: "/_1536322491_TileVideo-img_more.jpg",
    },

    pageMolotovPlus: {
      slider: "/_1541514029_carrousel_PLUS.png",
      slider2x: "/_1541514033_carrousel_PLUS@2x.png",
      tv: "/_1541001750_PageMolotovPlus_tv.png",
      tv2x: "/_1541001755_PageMolotovPlus_tv@2x.png",
    },

    pageTheMatch: {
      logo: "/_1542710014_LogoTheMatchSite.png",
      description1: "/_1542712136_PageTheMatch_Description1.png",
      description2: "/_1542712194_PageTheMatch_Description2.png",
      description3: "/_1542712250_PageTheMatch_Description3.png",
      description4: "/_1542712257_PageTheMatch_Description4.png",
      PageTheMatchOGImage: "/_1542720801_PageTheMatchOGImage.jpg",
      deviceImage: "/_1542796797_PageTheMatch_deviceImage.png",
    },

    pageGOT: {
      carousel: "/_1551884349_PageGOT-carousel.png",
      casting: {
        arya: "/_1551883502_PageGOT-casting-arya.jpg",
        bran: "/_1551883502_PageGOT-casting-bran.jpg",
        brienne: "/_1551883502_PageGOT-casting-brienne.jpg",
        cersei: "/_1551883502_PageGOT-casting-cersei.jpg",
        daenerys: "/_1551883502_PageGOT-casting-daenerys.jpg",
        davos: "/_1551883503_PageGOT-casting-davos.jpg",
        jaime: "/_1551883503_PageGOT-casting-jaime.jpg",
        jon: "/_1551969128_PageGOT-casting-jon.jpg",
        sansa: "/_1551883504_PageGOT-casting-sansa.jpg",
        samwell: "/_1551883503_PageGOT-casting-samwell.jpg",
        tyrion: "/_1551883504_PageGOT-casting-tyrion.jpg",
        varys: "/_1551883507_PageGOT-casting-varys.jpg",
      },
      downloadBlock: "/_1551889622_PageGOTDownloadBlock.png",
      iphone: "/_1551715735_PageGOT-iphone-x-mockup@3x.png",
      logoGOT: "/_1551876146_logo-got@3x.png",
      logoMolotov: "/_1676651708_molotov_by_fubo_blackandyellow.png",
      logoOCS: "/_1551715186_PageGOTLogoOCS.svg",
      logoOCSWhite: "/_1551977661_PageGOTLogoOCSWhite.png",
      ogImage: "/_1551781729_PageGOT-og-image.png",
      seasonImage1: "/_1551715867_PageGOT-saison-8-jon-snow-daenerys-targaryen@2x.jpg",
      seasonImage2: "/_1551715867_PageGOT-saison-8-bran-stark@2x.jpg",
    },

    pageMytho: {
      carousel: "/_1569940528_Mytho_defil_vign_arte.png",
      casting: {
        elvira: "/_1569940530_Mytho_marina.jpg",
        patrick: "/_1569940530_Mytho_mathieu.jpg",
        carole: "/_1569940529_Mytho_marie.jpg",
        sam: "/_1569940529_Mytho_jeremy.jpg",
        virginie: "/_1570091788_Mytho_zely4.jpg",
      },
      downloadBlock: "/_1569940627_Mytho_mockups_2x.png",
      iphone: "/_1569940528_Mytho_iphoneX_mockup_3x.png",
      logoArte: "/_1569940529_Mytho_logo_arte.svg",
      ogImage: "/_1569944072_Mytho_open_graph",
    },

    PageWestworld: {
      season1: "/_1583503938_westworldSeason1.png",
      season2: "/_1583503942_westworldSeason2.png",
      season3: "/_1583504250_westworldSeason3.png",
      dolores: "/_1583510462_dolores.png",
      caleb: "/_1583510575_caleb.png",
      harris: "/_1583510629_harris.png",
      maeve: "/_1583510671_maeve.png",
      tessa: "/_1583510711_tessa.png",
      jeffrey: "/_1583510758_jeffrey.png",
      download: "/_1583511947_downloadOCS.png",
      OCSLogo: "/_1583749148_OCSLogo.png",
      molotovLogoWhite: "/_1583749219_MolotovWhite.png",
      menu: "/_1583859209_westworldMenu.png",
    },

    PageMarketingOCS: {
      headerBg: "/_1662671114_Header_OCS_hotd.jpg",
      headerLogo: "/_1554902559_PageMarketingOCSLogoHeader.png",
      headerLogoOP: "/_1617293759_PageMarketingOCSNEWLogo.png",
      logoCTA: "/_1554902702_PageMarketingOCSLogoCTA.png",
      logoCTAOP: "/_1617291074_PageMarketingOCSNEWLogoHeader.jpg",
      programs: "/_1566979267_PageMarketingOcs-column2.png",
      tv: "/_1566984770_PageMarketingOcs-tv.png",
    },

    PageMarketingAdultSwim: {
      menu: "/_1609923449_PageMarketing-AdultSwimHeader-menu.png",
      logo: "/_1623929584_PageMarketing-AdultSwimHeader-logo.png",
      headerBg: "/_1623945545_PageMarketing-AdultSwimHeader-BG.jpg",
      headerLogo: "/_1623936579_PageMarketing-AdultSwimHeader-logo-AdultSwim.png",
      tv: "/_1568722524_PageMarketingAdultSwim-tv.png",
      programs: "/_1623940334_PageMarketing-AdultSwimHeader-Programs.png",
      logoCTA: "/_1609798750_PageMarketingAdultSwimLogoCTA.png",
      downloadBlock: "/_1623940238_PageMarketingAdultSwim-downloadBlock.png",
      sliderTilesImage: "/_1623937327_PageMarketingAdultswim-sliderTilesImage.png",
      sliderTilesImage2x: "/_1623937611_PageMarketingAdultswim-sliderTilesImagex2.png",
      headerDateImage: "/_1609785692_PageMarketing-AdultSwimHeader-Date.png",
      headerPricePromoImage: "/_1609786526_PageMarketing-AdultSwimHeader-Price.png",
      promoPrice: "/_1609857147_PageMarketingAdultSwim-price.png",
    },

    PageRickEtMorty: {
      RickMortyLogo: "/_1623766049_R&M_Logo_1@3x.png",
      timelineSaison1: "/_1572513646_RicketMortyS01.jpg",
      timelineSaison2: "/_1572513650_RicketMortyS02.jpg",
      timelineSaison3: "/_1572513655_RicketMortyS03.jpg",
      timelineSaison4: "/_1588162330_RicketMortyS04.png",
      timelineSaison5: "/_1624034267_PageMarketingRickMorty_s5.jpg",
      timelineSaison6: "/_1663144144_page_rick_morty_s6_poster.jpg",

      characters: {
        rick: "/_1662709170_avatar_rick.png",
        morty: "/_1662709183_img_avatar_morty.png",
        summer: "/_1662709192_img_avatar_summer.png",
        beth: "/_1662709202_img_avatar_beth.png",
        jerry: "/_1662709211_img_avatar_jerry.png",
      },
      bestEpisodes: {
        s1e6: "/_1573032289_RAM_s1e6.jpg",
        s3e1: "/_1573032285_RAM_s3e1.jpg",
        s3e7: "/_1573032189_RAM_s3e7.jpg",
      },
      szechuan: "/_1662716152_rick_morty_sauce.png",
      carousel: "/_1572951165_carousel-programs-rickMorty.png",
      download: "/_1663143859_page_rick_morty_download_2x.png",
      menu: "/_1573224953_rick-and-morty-54f8e5cc82a36.png",
    },

    Footer: {
      downloadIcons: "/_1554891278_FooterDownloadIcons.png",
      downloadIconsDark: "/_1572345513_FooterDownloadIcons-Dark.png",
    },

    pageGifts: {
      checkmarkImage: "/_1536311699_PageTravel-checkmark.svg",
      gift: "/_1542378324_gift.svg",
      wave: {
        cine: "/_1542367834_vagues_CINE@2x.png",
        extended: "/_1542367837_vagues_EXTENDED@2x.png",
        ocs: "/_1542367843_vagues_OCS@2x.png",
        plus: "/_1542367846_vagues_PLUS@2x.png",
      },
    },

    PageBoulanger: {
      carousel: "/_1558960760_PageBoulanger-carousel.png",
      download: "/_1558971236_PageBoulanger-device.png",
      download2x: "/_1558971236_PageBoulanger-device@2x.png",
      header: "/_1558958648_PageBoulangerHeader.jpg",
      logo: "/_1559898239_PageBoulanger-Boulanger-logo.png",
    },

    pageStarzPlay: {
      starzplayLogo: "/_1614272496_illus_starzplay_logo_2x.png",
      londonLogo: "/_1614272567_illus_starzplay_gangs_of_london_2x.png",
      bmfLogo: "/_1630874156_illus_starzplay_bmf_logo.webp",
      bmfLogoBis: "/_1631091074_illus_starzplay_bmf_logo_bis.png",
      bmfSubLogo: "/_1630874646_illus_starzplay_bmf_new.webp",
      bmfSubLogoBis: "/_1631091077_illus_starzplay_bmf_new_bis.png",
      kananLogo: "/_1630874160_illus_starzplay_kanan_logo.webp",
      kananLogoBis: "/_1631091080_illus_starzplay_kanan_logo_bis.png",
      drDeathLogo: "/_1630874165_illus_starzplay_dr_death_logo.webp",
      drDeathLogoBis: "/_1631091087_illus_starzplay_dr_death_logo_bis.png",
      harlemLogo: "/_1614272844_illus_starzplay_godfather_of_harlem_logo_2x.png",
    },

    pageXiaomi: {
      headerImage: "/_1598211219_illus_Xiaomi_header.png",
      headerImage2x: "/_1598211223_illus_Xiaomi_header2x.png",
      xiaomiImage: "/_1598211226_illus_Xiaomi_tv.png",
      xiaomiImage2x: "/_1598211228_illus_Xiaomi_tv2x.png",
      xiaomiTvImage: "/_1598211231_illus_Xiaomi_image.png",
      xiaomiTvImage2x: "/_1598211234_illus_Xiaomi_image2x.png",
    },

    downloadBlock: {
      appStoreImage: "/_1536328211_PageNFL-appleStore.svg",
      compoDevicesImage: "/_1536328158_PageNFL-compo_devices.png",
      halfStarImage: "/_1536328201_PageNFL-halfStar.svg",
      logoTvImage: "/_1591189808_logoTvImage.png",
      logoTv2xImage: "/_1591189808_logoTvImage.png",
      logoTvMobileImage: "/_1591190120_logoTvImageMobile.png",
      playStoreImage: "/_1536328211_PageNFL-google-play-store.png",
      starImage: "/_1536328201_PageNFL-star.svg",
    },

    pageSubscribe: {
      molotovByFuboLogo: "/_1676651708_molotov_by_fubo_hardblack1.png",
    },

    dsp: {
      ibloo: {
        logo: "/_1564479236_logo-ibloo.png",
        logox2: "/_1564479237_logo-ibloo@2x.png",
        logoProduct: "/_1580119985_ibloo-logo-sub.png",
        sliderImages: [
          {
            url: "/_1567435958_ibloo-slider-image-1.png",
            urlx2: "/_1567435983_ibloo-slider-image-1@2x.png",
          },
          {
            url: "/_1567436019_ibloo-slider_image2.png",
            urlx2: "/_1567436019_ibloo-slider_image2@2x.png",
          },
          {
            url: "/_1567436072_ibloo-slider_image3.png",
            urlx2: "/_1567436020_ibloo-slider_image3@2x.png",
          },
        ],
      },
    },

    molotovMultiDevices: {
      banner: {
        jp2: "/_1622539909_Home_4_ecrans_header.jp2",
        webp: "/_1622539909_Home_4_ecrans_header.webp",
      },

      banner2x: {
        jp2: "/_1622538657_Home_4_ecrans_header@x2.jp2",
        webp: "/_1622538653_Home_4_ecrans_header@x2.webp",
      },
    },
  },

  doc: {
    legal: {
      reglement_molotov_on_instagram: "/_1614069551_reglement_jeu_molotov_on_instagram.pdf",
      reglement_molotov_x_manon: "/_1615199436_Reglement_jeu_Manon_X_Molotov_202103.pdf",
      reglement_molotov_x_hoversmash: "/_1615565430_reglement-jeu-hoversmash-x-molotov.pdf",
      reglement_molotov_x_julien_chieze: "/_1616162547_reglement_jeu_Julien_Chieze_X_Molotov.pdf",
      reglement_molotov_x_kev: "/_1616760450_Reglement_jeu_CAMKEV_X_Molotov.pdf",
      reglement_got_10_ans_insta: "/_1617955597_REGLEMENT_DE_JEU_GOT_10_ans_Version_Instagram_Molotov_avril_2021.pdf",
      reglement_tu_as_la_ref: "/_1620393271_reglement_de_jeu_tu_as_la_ref_Version_Instagram_Molotov_mai_2021.pdf",
      reglement_tom_et_jerry: "/_1621412109_Tom_Jerry_X_Molotov_mai_2021.pdf",
    },
  },
}
