import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { setSEOFooterHeight } from "actions/ui"

import styles from "./index.css"
import i18n from "consts/i18n"
import consts from "consts"
import classNames from "classnames"
import { get } from "lodash"
import formatterHelper from "helpers/formatter"
import { selectReferencesModel } from "selectors/references"
import { open as openModal } from "../../actions/modal"
import modalTypes from "../Modal/types"

@connect(state => ({
  seoFooterHeight: state.ui.seoFooterHeight,
  windowHeight: state.ui.windowHeight,
  windowWidth: state.ui.windowWidth,
  references: selectReferencesModel(state),
  locale: state.locale.locale,
}))
export default class SEOFooter extends Component {
  static propTypes = {
    categoryId: PropTypes.number,
    dispatch: PropTypes.func.isRequired,
    programId: PropTypes.number,
    seoFooterHeight: PropTypes.number,
    windowHeight: PropTypes.number,
    windowWidth: PropTypes.number,
    references: PropTypes.object,
    locale: PropTypes.string,
  }

  openCookieModal = e => {
    e?.preventDefault()
    this.props.dispatch(openModal(modalTypes.cookie, null, null, false))
  }

  componentDidMount() {
    if (this.footer) {
      this.props.dispatch(setSEOFooterHeight(this.footer.offsetHeight))
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.windowWidth !== this.props.windowWidth ||
      prevProps.windowHeight !== this.props.windowHeight ||
      prevProps.references.channels.length !== this.props.references.channels.length
    ) {
      this.props.dispatch(setSEOFooterHeight(this.footer.offsetHeight))
    }
  }

  render() {
    const { references, locale, seoFooterHeight } = this.props

    const channels = references
      .getTvBundles()
      .reduce((acc, bundle) => {
        return acc.concat(bundle.channels)
      }, [])
      .filter(channel => !channel.isVirtual())

    const categories = references.categories

    const now = new Date()

    return (
      <div className={styles.root} ref={footer => (this.footer = footer)} style={{ marginTop: `-${seoFooterHeight}px` }}>
        <div className={styles.container}>
          <div className={classNames(styles.mainContainer)}>
            <div className={classNames(styles.content)}>
              <div className={classNames(styles.title)}>Découvrez-nous</div>
              <ul className={styles.list}>
                <li>
                  <a href={consts.externalLink.press + "/fr/"}>{i18n.press}</a>
                </li>
                <li>
                  <a href={consts.externalLink.press + "/en/"}>{i18n.pressRelease}</a>
                </li>
                <li>
                  <a href={"https://advertising.molotov.tv"} target={"_blank"} className={styles.mailto}>
                    Molotov Advertising
                  </a>
                </li>
                <li>
                  <Link to={consts.routes.careers}>{i18n.job_offers}</Link>
                </li>
              </ul>
            </div>
            <div className={classNames(styles.content)}>
              <div className={classNames(styles.title)}>Besoin d'aide</div>
              <ul className={styles.list}>
                <li>
                  <a href={consts.externalLink.support} target="_blank">
                    {i18n.helpOnline}
                  </a>
                </li>
                <li>
                  <Link to={consts.routes.privacy}>{i18n.privacyPolicy}</Link>
                </li>
                <li>
                  <Link to={consts.routes.mentions}>{i18n.mentions}</Link>
                </li>
                <li>
                  <Link to={consts.routes.cookies}>{i18n.cookies}</Link>
                </li>
                <li>
                  <Link to={consts.routes.gtu}>{i18n.termsOfUse}</Link>
                </li>
                <li>
                  <span
                    aria-label="cookie preferences"
                    onClick={() => {
                      this.openCookieModal()
                      window.Didomi.preferences.show()
                    }}
                  >
                    {i18n.cookieSettings.editSettings}
                  </span>
                </li>
              </ul>
            </div>
            <div className={classNames(styles.content)}>
              <div className={classNames(styles.title)}>Sur Molotov</div>
              <ul className={styles.list}>
                {!!channels.length &&
                  consts.onMolotovChannels.map(channel => {
                    const foundChannel = channels.find(chan => channel === get(chan, "data.slug_seo", ""))
                    return (
                      <li key={foundChannel.getTitle()}>
                        <Link to={foundChannel.getUrl(locale)}>{foundChannel.getTitle()} direct</Link>
                      </li>
                    )
                  })}
              </ul>
            </div>
            <div className={classNames(styles.content)}>
              <div className={classNames(styles.title)}>Nos programmes</div>
              <ul className={styles.list}>
                <li>
                  <Link to={formatterHelper.basic(consts.routes.seoHome, { locale })}>{i18n.publicMenu.seoHome}</Link>
                </li>
                <li>
                  <Link
                    to={formatterHelper.basic(consts.routes.seoHomeSection, {
                      locale,
                      section: consts.slugs.tonight_1,
                    })}
                  >
                    {i18n.publicMenu.seoHomeTonight}
                  </Link>
                </li>
                {!!categories.length &&
                  consts.footerCategories.map(category => {
                    const foundCategory = categories.find(cat => category === get(cat, "slug_seo", ""))
                    return (
                      <li key={foundCategory.id}>
                        <Link
                          to={formatterHelper.basic(consts.routes.category, {
                            locale,
                            categoryId: foundCategory.id,
                            categorySlug: foundCategory.slug,
                          })}
                        >
                          {foundCategory.label} streaming
                        </Link>
                      </li>
                    )
                  })}
                <li>
                  <a href={consts.externalLink.molotovTvAwards} aria-label="molotov tv awards">
                    {i18n.publicMenu.molotovTvAwards}
                  </a>
                </li>
              </ul>
            </div>
            <div className={classNames(styles.content)}>
              <div className={classNames(styles.title)}>Nos offres</div>
              <ul className={styles.list}>
                <li>
                  <a href={consts.externalLink.extra} aria-label="molotov extra">
                    {i18n.publicMenu.molotovExtra}
                  </a>
                </li>
                <li>
                  <a href={consts.externalLink.extended} aria-label="molotov extended">
                    {i18n.publicMenu.molotovExtended}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.copyright}>©Molotov {now.getFullYear()}</div>
        </div>
      </div>
    )
  }
}
