import { push } from "connected-react-router"

import consts from "consts"

import { fetchPaymentForm, fetchPasswordForm } from "actions/mtvPay"
import { fetchGiftBuy, fetchGiftClaim, fetchGiftOptions, fetchGiftSuccess, fetchPromoCodeSuccess } from "actions/gift"
import { fetchPromocodeClaim } from "actions/promoCode"

import helpers from "helpers/formatter"

export function redirect(template, action) {
  return (dispatch, getState) => {
    const state = getState()

    console.log(template, consts.templateMap.payment_card)

    switch (template) {
      case consts.templateMap.payment_card:
      case consts.templateMap.payment_before_pay_periodicity_selection:
        dispatch(fetchPaymentForm(action, action.section))
        break

      case consts.templateMap.payment_password:
        dispatch(fetchPasswordForm(action, action.section))
        break

      case consts.templateMap.web_view:
        const { frameless, platform } = state.appSettings
        // for apple we need to use _top as target or the new page won't open
        const target = frameless && platform === "apple" ? "_top" : "_self"
        window.open(`${action.url}${frameless ? "?frameless=true" : ""}`, target)
        break

      case consts.templateMap.gift_claim:
        dispatch(fetchGiftClaim(action))
        break

      case consts.templateMap.gift_offer:
        dispatch(fetchGiftOptions(action))
        break

      case consts.templateMap.promocode_success:
        dispatch(fetchPromoCodeSuccess(action))
        break

      case consts.templateMap.gift_success:
        dispatch(fetchGiftSuccess(action))
        break

      case consts.templateMap.product_subscribe:
        dispatch(fetchGiftBuy(action))
        break

      case consts.templateMap.gift_list:
        dispatch(push(consts.routes.giftlist))

      case consts.templateMap.catalog:
        dispatch(push(consts.routes.default))
        break

      case consts.templateMap.product_catalog:
        dispatch(push(consts.routes.offers))
        break

      case consts.templateMap.programs:
        const locale = state.locale.locale || "fr_fr"
        dispatch(push(helpers.basic(consts.routes.seoHome, { locale })))
        break

      case consts.templateMap.promocode_claim:
        dispatch(fetchPromocodeClaim(action))
        break

      case consts.templateMap.reset_password:
        dispatch(push(consts.routes.resetPassword))
        break

      case consts.templateMap.external_link:
        window.open(action.url)
        break

      case consts.templateMap.generic:
        // we do not need the complete url
        // so we remove it if needed
        let url = action.url
        try {
          const fullUrl = new URL(action.url)
          url = action.url.replace(fullUrl.origin, "")
        } catch (e) {}

        dispatch(push(url))
        break
    }
  }
}
